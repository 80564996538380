import React, { useEffect, useState } from 'react';
import './MyCustomChatMessage.css'
import { getChatbot } from "../api/service";



const MyCustomChatMessage = (props) => {
    const [time, setTime] = useState(new Date());
    const [read, setRead] = useState(new Date());
   
    const [color, setColor] = useState('#1972F5');
 
    // Fonction pour récupérer les données du chatbot
    const fetchChatbotData = async (chatBotId) => {
        try {
            const response = await getChatbot(chatBotId);
            const chatbotData = response.data;;
            
            if (chatbotData) {
                setColor(chatbotData.modules.chatbot.color || '#1972F5');
                console.log(chatbotData.modules.chatbot.color);
                
                document.querySelector('.react-chatbot-kit-chat-btn-send').style.opacity = 0.7
                document.querySelector('.react-chatbot-kit-chat-btn-send svg').style.fill = chatbotData.modules.chatbot.color
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données du chatbot :', error);
        }
    };



    useEffect(() => {
        const chatBotId = window.location.href.includes('chatbot_id=')
            ? window.location.href.split('chatbot_id=')[1].split('&')[0]
            : null;

        if (chatBotId) {
            fetchChatbotData(chatBotId);
        }
    }, []);

    useEffect(() => {
        setTime(new Date());
        setRead(new Date());
    }, []);

    return (
        <div className={'message'}>
            <div className={'message-con-bot'} style={{background: `${color}30`, color: color}} dangerouslySetInnerHTML={{ __html: props.message }}></div>
            <span style={{
                    color: color,
                    position: 'absolute',
                    left: '1px',
                    fontSize: '13px'
                }}> {time && time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
        </div>
    )
}

export default MyCustomChatMessage
