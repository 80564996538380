import React from 'react';
import { Button, Box } from '@mui/material';
import { createClientMessage } from 'react-chatbot-kit';
import { NoEncryption } from '@mui/icons-material';

const Choices = (props) => {
    const { setState, payload, states, actionProvider } = props;
    const { content, color } = payload;  // Assurez-vous que content est une liste de valeurs

    console.log(color);


    // Extrait le chat_bot_id de l'URL, si présent
    const chat_bot_id = window.location.href.includes('chatbot_id=')
        ? window.location.href.split('chatbot_id=')[1].split('&')[0]
        : null;

    const createChatUserMessage = (msg) => {
        const message = createClientMessage(
            msg
        );
        setState(prevState => ({
            ...prevState,
            messages: [...prevState.messages, message]
        }))
        actionProvider.next(null, msg);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                padding: '20px',
                width: '100%', // Prend la largeur totale du conteneur parent
            }}
        >
            {
                content.map((item, index) => (
                    <Button
                        key={index}
                        variant="contained"
                        size="medium"
                        onClick={() => createChatUserMessage("je souhaite de l'aide pour : " + item.name)}
                        sx={{
                            color: color, // Couleur du texte du bouton
                            backgroundColor: '#ffffff', // Couleur de fond du bouton
                            border: `1px solid ${color}`, // Correctement formaté avec les backticks
                            marginTop: '10px',
                            padding: '5px',
                            borderRadius: '8px',
                            textTransform: 'none',
                            height: '75px',
                            width: '45%', // Remplit la largeur disponible
                            maxWidth: '200px', // Optionnel : limite maximale
                            fontSize: '12px',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: color, // Couleur de fond au survol
                                color: '#ffffff', // Optionnel : Couleur du texte au survol
                            },
                        }}
                    >
                        {item.name}
                    </Button>

                ))
            }
        </Box >

    );
};

export default Choices;
